export const HUBS = Object.freeze({
    user: {
        notification: 'NotificationsHub'
    },
    outbound: {
        good: {
            validation: 'FileValidation/CustomerDeliveryGoodPartsUploadHub',
            submit: 'SubmitOrders/CustomerDeliveryGoodPartsUploadHub'
        },
        defective: {
            validation:
                'FileValidation/ReturnPartsDefectiveFromChoiceUploadHub',
            submit: 'SubmitOrders/ReturnPartsDefectiveFromChoiceUploadHub'
        }
    },
    inbound: {
        good: {
            validation: 'FileValidation/ReplenishmentsGoodPartsUploadHub',
            submit: 'SubmitOrders/ReplenishmentsGoodPartsUploadHub'
        },
        defective: {
            validation: 'FileValidation/ReturnPartsDefectiveToChoiceUploadHub',
            submit: 'SubmitOrders/ReturnPartsDefectiveToChoiceUploadHub'
        }
    },
    transfers: {
        good: {
            validation: 'FileValidation/WarehouseTransfersGoodPartsUploadHub',
            submit: 'SubmitOrders/WarehouseTransfersGoodPartsUploadHub'
        },
        defective: {
            validation:
                'FileValidation/WarehouseTransfersDefectivePartsUploadHub',
            submit: 'SubmitOrders/WarehouseTransfersDefectivePartsUploadHub'
        }
    }
});

export enum EVENTS {
    RECEIVE_SUBMISSION = 'ReceiveSubmissionProgress',
    RECEIVE_FILE_VALIDATION = 'ReceiveFileValidationProgress',
    RECEIVE_USER_STATUS = 'ReceiveUserStatusMessage'
}
