import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithIntercept } from '@apis/fetchBaseQuery';
import { REQUESTS_PATH } from '@apis/notifications/Notifications.type';
import {
    NotificationsRequest,
    NotificationsResponse
} from '@customTypes/notifications/notifications.type';

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: baseQueryWithIntercept,
    endpoints: (builder) => ({
        getNotifications: builder.mutation<
            NotificationsResponse,
            NotificationsRequest
        >({
            query: (dto) => ({
                url: REQUESTS_PATH.GET_NOTIFICATIONS,
                method: 'POST',
                body: dto
            })
        })
    })
});

export const { useGetNotificationsMutation } = notificationsApi;
