import React from 'react';

import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';

import { ToolTip } from '@atoms/tooltip/ToolTip';
import { FeatureFlagsController } from '@configs/FeatureFlagsController';
import { DeviceType } from '@constants/breakpoints';
import {
    MENU_SECTIONS_PERMISSION_KEYS,
    PERMISSIONS_KEYS
} from '@constants/permissionsKeys';
import { RoutesList } from '@constants/routesList';
import { SHOW_MODE } from '@customTypes/general/featureFlags.types';
import { ElementsType } from '@molecules/siderMenu/SiderMenuItem.types';
import { resetOrderState } from '@store/slices/activeOrder/activeOrder';
import { resetBulkState } from '@store/slices/bulk/bulk';
import { UserTokenInfo } from '@store/slices/user/user';
import {
    SvgCreateInbound,
    SvgCreateOutbound,
    SvgCreateTransfers,
    SvgFinancials,
    SvgManageInventory,
    SvgManageParts,
    SvgMangePartsSideIcon,
    SvgPerformance,
    SvgPin,
    SvgSideChoiceLogo,
    SvgSiteAdministration,
    SvgUsers
} from 'components/icons';
import { RootState } from 'store';

import styles from './SiderMenu.module.less';
export interface IRedirectList {
    key: string;
    redirectTo: string;
    getRedirectBlocker?: (state: RootState) => boolean;
    nestedItems?: string[];
    resetStateAction?: ActionCreatorWithoutPayload;
    permission?: string;
}

interface IGetSideBarItem {
    userInfo: UserTokenInfo | null;
    withoutFlagsAndPermissions?: boolean;
    itemsWithPermissions?: ElementsType[];
}

type GetAllSideBarType = (
    userInfo: UserTokenInfo | null,
    withoutFlagsAndPermissions?: boolean
) => ElementsType[];

export const SideBarItemsWithPermissions: ElementsType[] = [
    {
        label: 'Home',
        key: 'home',
        icon: <SvgSideChoiceLogo />,
        mode: SHOW_MODE.ALWAYS,
        permission: MENU_SECTIONS_PERMISSION_KEYS.home
    },
    {
        label: 'Track Orders',
        key: 'trackOrders',
        icon: <SvgPin />,
        mode: SHOW_MODE.ALWAYS,
        permission: MENU_SECTIONS_PERMISSION_KEYS.trackOrders
    },
    {
        label: 'Customer Delivery',
        key: 'outbound Orders',
        mode: SHOW_MODE.ALWAYS,
        permission: MENU_SECTIONS_PERMISSION_KEYS.outboundOrders,
        icon: <SvgCreateOutbound />,
        children: [
            {
                label: 'Good Parts',
                key: 'goodByPart',
                permission: PERMISSIONS_KEYS.orderByPart,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Good Parts (Upload)',
                permission: PERMISSIONS_KEYS.outboundOrderByBulk,
                key: 'goodByUpload',
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Replenishments',
        key: 'materialsManagement',
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgManageParts />,
        permission: MENU_SECTIONS_PERMISSION_KEYS.replenishments,
        children: [
            {
                label: 'Good Parts',
                key: 'inboundReplenishment',
                permission: PERMISSIONS_KEYS.inboundReplenishment,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Good Parts (Upload)',
                key: 'inboundGoodByUpload',
                permission: PERMISSIONS_KEYS.inboundOrderByBulk,
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet
            }
        ]
    },
    {
        label: 'Warehouse Transfers',
        key: 'createTransfer',
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgCreateTransfers />,
        permission: MENU_SECTIONS_PERMISSION_KEYS.createTransfer,
        children: [
            {
                label: 'Good Parts',
                key: 'transferGoodByWarehouse',
                permission: PERMISSIONS_KEYS.interWarehouseTransferByWarehouse,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Good Parts (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Good Parts (Upload)
                    </ToolTip>
                ),
                key: 'transferGoodByUpload',
                permission: PERMISSIONS_KEYS.transferGoodByUpload,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Defective Parts',
                key: 'transferDefectiveByWarehouse',
                permission: PERMISSIONS_KEYS.transferDefectiveByWarehouse,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective Parts (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective Parts (Upload)
                    </ToolTip>
                ),
                key: 'transferDefectiveByUpload',
                permission: PERMISSIONS_KEYS.transferDefectiveByUpload,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    //
    {
        label: 'Return Parts',
        key: 'returnParts',
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgCreateInbound />,
        permission: MENU_SECTIONS_PERMISSION_KEYS.materialsManagement,
        children: [
            {
                label: 'Defective to Choice',
                permission: PERMISSIONS_KEYS.defectiveByPart,
                mode: SHOW_MODE.ALWAYS,
                key: 'defectiveToChoice'
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective to Choice (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective to Choice (Upload)
                    </ToolTip>
                ),
                permission: PERMISSIONS_KEYS.inboundDefectiveByUpload,
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet,
                key: 'defectiveToChoiceUpload'
            },
            {
                label: 'Good to Choice',
                permission: PERMISSIONS_KEYS.inboundReturn,
                mode: SHOW_MODE.ALWAYS,
                key: 'goodToChoice'
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective from Choice"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective from Choice
                    </ToolTip>
                ),
                permission: PERMISSIONS_KEYS.defectiveByWarehouse,
                mode: SHOW_MODE.ALWAYS,
                key: 'defectiveFromChoice'
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Defective from Choice (Upload)"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Defective from Choice (Upload)
                    </ToolTip>
                ),
                permission: PERMISSIONS_KEYS.defectiveByUpload,
                key: 'defectiveFromChoiceUpload',
                mode: SHOW_MODE.ALWAYS,
                blocked: DeviceType.Tablet
            },
            {
                label: 'Good from Choice',
                key: 'goodFromChoice',
                permission: PERMISSIONS_KEYS.orderByWarehouse,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    //
    {
        label: 'Manage Parts',
        key: 'manageParts',
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgMangePartsSideIcon />,
        permission: MENU_SECTIONS_PERMISSION_KEYS.manageParts,
        children: [
            {
                label: 'Part Master',
                key: 'partMaster',
                permission: PERMISSIONS_KEYS.partMaster,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Substitute Parts',
                key: 'substituteParts',
                permission: PERMISSIONS_KEYS.substituteParts,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Manage Inventory',
        key: 'manageInventory',
        mode: SHOW_MODE.ALWAYS,
        icon: <SvgManageInventory />,
        permission: MENU_SECTIONS_PERMISSION_KEYS.manageInventory,
        children: [
            {
                label: 'Inventory On Hand',
                key: 'inventoryOnHand',
                permission: PERMISSIONS_KEYS.inventoryOnHand,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Inventory Transaction History"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Inventory Transaction History
                    </ToolTip>
                ),
                key: 'inventoryTransactionHistory',
                permission: PERMISSIONS_KEYS.inventoryTransactionHistory,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Material In Review',
                key: 'materialInReview',
                permission: PERMISSIONS_KEYS.materialInReview,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Materials In Review Analysis"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Materials In Review Analysis
                    </ToolTip>
                ),
                key: 'materialInReviewAnalysis',
                permission: PERMISSIONS_KEYS.materialInReviewAnalysis,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Missing Parts',
                key: 'missingParts',
                permission: PERMISSIONS_KEYS.missingPart,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Outstanding Inbounds',
                key: 'outstandingInbounds',
                permission: PERMISSIONS_KEYS.outstandingInbounds,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Outstanding Returns',
                key: 'outstandingReturns',
                permission: PERMISSIONS_KEYS.outstandingReturns,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Locate Closest Warehouse',
                key: 'locateClosestWarehouse',
                permission: PERMISSIONS_KEYS.locateClosestWarehouse,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Financials',
        key: 'financials',
        mode: SHOW_MODE.ALWAYS,
        permission: MENU_SECTIONS_PERMISSION_KEYS.financials,
        icon: <SvgFinancials />,
        children: [
            {
                label: 'Invoices',
                key: 'invoices',
                permission: PERMISSIONS_KEYS.invoices,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Storage Billing',
                key: 'storageBilling',
                permission: PERMISSIONS_KEYS.storageBilling,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Client Billing',
                key: 'clientBilling',
                permission: PERMISSIONS_KEYS.clientBilling,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Client Spend',
                key: 'clientSpend',
                permission: PERMISSIONS_KEYS.clientSpend,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Duties & Taxes Analysis',
                key: 'dutiesTaxesAnalysis',
                permission: PERMISSIONS_KEYS.dutiesTaxesAnalysis,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    },
    {
        label: 'Analytics',
        key: 'analytics',
        mode: SHOW_MODE.ALWAYS,
        permission: MENU_SECTIONS_PERMISSION_KEYS.analytics,
        icon: <SvgPerformance />,
        children: [
            {
                label: 'Order History',
                key: 'orderHistory',
                permission: PERMISSIONS_KEYS.analyticsOrderHistory,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Cycle Count Performance',
                key: 'cycleCountPerformance',
                permission: PERMISSIONS_KEYS.cycleCountPerformance,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Global Capabilities',
                key: 'globalCapabilities',
                permission: PERMISSIONS_KEYS.globalCapabilities,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Dock to Stock',
                key: 'dockToStock',
                permission: PERMISSIONS_KEYS.dockToStock,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Outbound Drive Fill Rate"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Outbound Drive Fill Rate
                    </ToolTip>
                ),
                key: 'outboundDriveFillRate',
                permission: PERMISSIONS_KEYS.outboundDriveFillRate,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: 'Outbound Drive Performance',
                key: 'outboundDrivePerformance',
                permission: PERMISSIONS_KEYS.outboundDrivePerformance,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            },
            {
                label: (
                    <ToolTip
                        overlayClassName={styles.Tooltip}
                        title="Tender to Carrier Performance"
                        placement="right"
                        renderToThePortal={true}
                    >
                        Tender to Carrier Performance
                    </ToolTip>
                ),
                key: 'tenderToCarrierPerformance',
                permission: PERMISSIONS_KEYS.tenderToCarrierPerformance,
                blocked: DeviceType.Tablet,
                mode: SHOW_MODE.ALWAYS
            }
        ]
    }
];

const getAdminPanelChildren = (
    withoutFlagsAndPermissions: boolean,
    isChoiceAdmin: boolean
) => {
    const availableForAdmin: ElementsType['children'] = [
        {
            label: 'Admins',
            key: 'admins',
            mode: SHOW_MODE.ALWAYS,
            permission: PERMISSIONS_KEYS.admins,
            blocked: DeviceType.Tablet
        },
        {
            label: 'Users',
            key: 'users',
            mode: SHOW_MODE.ALWAYS,
            permission: PERMISSIONS_KEYS.users,
            blocked: DeviceType.Tablet
        },
        {
            label: 'Profiles',
            key: 'profiles',
            mode: SHOW_MODE.ALWAYS,
            permission: PERMISSIONS_KEYS.profiles,
            blocked: DeviceType.Tablet
        }
    ];

    const availableForChoiceAdmin: ElementsType['children'] = [
        ...availableForAdmin,
        {
            label: 'Choice Administrators',
            key: 'choiceAdmins',
            mode: SHOW_MODE.ALWAYS,
            permission: PERMISSIONS_KEYS.choiceAdmins,
            blocked: DeviceType.Tablet
        }
    ];

    if (withoutFlagsAndPermissions) {
        return availableForChoiceAdmin;
    }

    return isChoiceAdmin ? availableForChoiceAdmin : availableForAdmin;
};

export const getSideBarItems = ({
    userInfo,
    withoutFlagsAndPermissions = false,
    itemsWithPermissions = SideBarItemsWithPermissions
}: IGetSideBarItem): ElementsType[] => {
    const isChoiceAdmin = Boolean(userInfo?.isChoiceAdmin);

    const AdminPanelItem: ElementsType = {
        label: isChoiceAdmin ? 'Site Administration' : 'User Administration',
        key: 'adminPanel',
        icon: isChoiceAdmin ? <SvgSiteAdministration /> : <SvgUsers />,
        children: getAdminPanelChildren(
            withoutFlagsAndPermissions,
            isChoiceAdmin
        ),
        permission: MENU_SECTIONS_PERMISSION_KEYS.adminPanel,
        blocked: DeviceType.Tablet
    };
    const adminsItems = [...itemsWithPermissions, AdminPanelItem];

    if (withoutFlagsAndPermissions) {
        return adminsItems;
    }

    const featureFlags = FeatureFlagsController.getInstance();
    const isUser = userInfo?.isUser;
    const menuItems = isUser ? itemsWithPermissions : adminsItems;

    return featureFlags.getFormattedSiderMenu(menuItems);
};

export const getAllSideBarItems: GetAllSideBarType = (
    userInfo: UserTokenInfo | null,
    withoutFlagsAndPermissions = false
) => getSideBarItems({ userInfo, withoutFlagsAndPermissions });

export const RedirectList: IRedirectList[] = [
    // HOME
    {
        key: 'home',
        redirectTo: RoutesList.HOME.ROOT,
        permission: MENU_SECTIONS_PERMISSION_KEYS.home
    },
    // TRACK ORDERS
    {
        key: 'trackOrders',
        redirectTo: RoutesList.TRACK_ORDERS.ROOT,
        nestedItems: [RoutesList.TRACK_ORDERS.ORDER_DETAILS.VIEW_FULL],
        permission: MENU_SECTIONS_PERMISSION_KEYS.trackOrders
    },
    // ORDERS
    {
        key: 'goodByPart',
        redirectTo: RoutesList.ORDERS.BY_PART.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: PERMISSIONS_KEYS.orderByPart
    },
    {
        key: 'goodByUpload',
        redirectTo: RoutesList.ORDERS.BULK.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.outboundOrderByBulk
    },
    // MATERIALS
    {
        key: 'inboundReplenishment',
        redirectTo: RoutesList.MATERIALS.REPLENISHMENT.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: PERMISSIONS_KEYS.inboundReplenishment
    },
    {
        key: 'inboundGoodByUpload',
        redirectTo: RoutesList.MATERIALS.BULK.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.inboundOrderByBulk
    },
    {
        key: 'transferGoodByWarehouse',
        redirectTo: RoutesList.TRANSFERS.BY_WAREHOUSE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: PERMISSIONS_KEYS.interWarehouseTransferByWarehouse
    },
    {
        key: 'transferDefectiveByWarehouse',
        redirectTo: RoutesList.TRANSFERS.BY_WAREHOUSE_DEFECTIVE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetOrderState,
        permission: PERMISSIONS_KEYS.transferDefectiveByWarehouse
    },
    {
        key: 'transferGoodByUpload',
        redirectTo: RoutesList.TRANSFERS.BULK.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.transferGoodByUpload
    },
    {
        key: 'transferDefectiveByUpload',
        redirectTo: RoutesList.TRANSFERS.BULK_DEFECTIVE.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.transferDefectiveByUpload
    },
    // RETURN PARTS
    {
        key: 'defectiveToChoice',
        redirectTo: RoutesList.RETURN_PARTS.DEFECTIVE_TO_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.defectiveByPart
    },
    {
        key: 'defectiveToChoiceUpload',
        redirectTo:
            RoutesList.RETURN_PARTS.DEFECTIVE_TO_CHOICE_UPLOAD.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.inboundDefectiveByUpload
    },
    {
        key: 'goodToChoice',
        redirectTo: RoutesList.RETURN_PARTS.GOOD_TO_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.inboundReturn
    },
    {
        key: 'defectiveFromChoice',
        redirectTo: RoutesList.RETURN_PARTS.DEFECTIVE_FROM_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.defectiveByWarehouse
    },
    {
        key: 'defectiveFromChoiceUpload',
        redirectTo:
            RoutesList.RETURN_PARTS.DEFECTIVE_FROM_CHOICE_UPLOAD.CREATE_FULL,
        getRedirectBlocker: ({ bulk }: RootState) => bulk.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.defectiveByUpload
    },
    {
        key: 'goodFromChoice',
        redirectTo: RoutesList.RETURN_PARTS.GOOD_FROM_CHOICE.CREATE_FULL,
        getRedirectBlocker: ({ activeOrder }: RootState) =>
            activeOrder.redirectBlocker,
        resetStateAction: resetBulkState,
        permission: PERMISSIONS_KEYS.orderByWarehouse
    },

    // MANAGE PARTS
    {
        key: 'partMaster',
        redirectTo: RoutesList.MANAGE_PARTS.PART_MASTER.READ_FULL,
        permission: PERMISSIONS_KEYS.partMaster
    },
    {
        key: 'substituteParts',
        redirectTo: RoutesList.MANAGE_PARTS.SUBSTITUTE_PARTS.READ_FULL,
        permission: PERMISSIONS_KEYS.substituteParts
    },
    // MANAGE INVENTORY
    {
        key: 'locateClosestWarehouse',
        redirectTo: RoutesList.MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE.FULL,
        getRedirectBlocker: () => false,
        resetStateAction: resetOrderState,
        permission: PERMISSIONS_KEYS.locateClosestWarehouse
    },
    {
        key: 'materialInReview',
        redirectTo: RoutesList.MANAGE_INVENTORY.MATERIALS_REVIEW.FULL,
        getRedirectBlocker: () => false,
        nestedItems: [
            RoutesList.MANAGE_INVENTORY.MATERIALS_REVIEW.MIR_DETAILS.VIEW_FULL
        ],
        permission: PERMISSIONS_KEYS.materialInReview
    },
    {
        key: 'materialInReviewAnalysis',
        redirectTo:
            RoutesList.MANAGE_INVENTORY.MATERIALS_IN_REVIEW_ANALYSIS.FULL,
        permission: PERMISSIONS_KEYS.materialInReviewAnalysis
    },
    {
        key: 'missingParts',
        redirectTo: RoutesList.MANAGE_INVENTORY.MISSING_PARTS.FULL,
        permission: PERMISSIONS_KEYS.missingPart
    },
    {
        key: 'inventoryTransactionHistory',
        redirectTo:
            RoutesList.MANAGE_INVENTORY.INVENTORY_TRANSACTION_HISTORY.FULL,
        permission: PERMISSIONS_KEYS.inventoryTransactionHistory
    },
    {
        key: 'outstandingInbounds',
        redirectTo: RoutesList.MANAGE_INVENTORY.OUTSTANDING_INBOUNDS.FULL,
        permission: PERMISSIONS_KEYS.outstandingInbounds
    },
    {
        key: 'outstandingReturns',
        redirectTo: RoutesList.MANAGE_INVENTORY.OUTSTANDING_RETURNS.FULL,
        permission: PERMISSIONS_KEYS.outstandingReturns
    },
    {
        key: 'inventoryOnHand',
        redirectTo: RoutesList.MANAGE_INVENTORY.INVENTORY_ON_HAND.FULL,
        permission: PERMISSIONS_KEYS.inventoryOnHand
    },
    // FINANCIALS
    {
        key: 'invoices',
        redirectTo: RoutesList.FINANCIALS.INVOICES.VIEW_FULL,
        permission: PERMISSIONS_KEYS.invoices
    },
    {
        key: 'storageBilling',
        redirectTo: RoutesList.FINANCIALS.STORAGE_BILLING.VIEW_FULL,
        permission: PERMISSIONS_KEYS.storageBilling
    },
    {
        key: 'clientSpend',
        redirectTo: RoutesList.FINANCIALS.CLIENT_SPEND.VIEW_FULL,
        permission: PERMISSIONS_KEYS.clientSpend
    },
    {
        key: 'dutiesTaxesAnalysis',
        redirectTo: RoutesList.FINANCIALS.DUTIES_TAXES_ANALYSIS.VIEW_FULL,
        permission: PERMISSIONS_KEYS.dutiesTaxesAnalysis
    },
    {
        key: 'clientBilling',
        redirectTo: RoutesList.FINANCIALS.CLIENT_BILLING.VIEW_FULL,
        permission: PERMISSIONS_KEYS.clientBilling
    },
    // ANALYTICS
    {
        key: 'orderHistory',
        redirectTo: RoutesList.ANALYTICS.ORDER_HISTORY.READ_FULL,
        permission: PERMISSIONS_KEYS.analyticsOrderHistory
    },
    {
        key: 'cycleCountPerformance',
        redirectTo: RoutesList.ANALYTICS.CYCLE_COUNT_PERFORMANCE.READ_FULL,
        permission: PERMISSIONS_KEYS.cycleCountPerformance
    },
    {
        key: 'globalCapabilities',
        redirectTo: RoutesList.ANALYTICS.GLOBAL_CAPABILITIES.READ_FULL,
        permission: PERMISSIONS_KEYS.globalCapabilities
    },
    {
        key: 'dockToStock',
        redirectTo: RoutesList.ANALYTICS.DOCK_TO_STOCK.READ_FULL,
        permission: PERMISSIONS_KEYS.dockToStock
    },
    {
        key: 'outboundDriveFillRate',
        redirectTo: RoutesList.ANALYTICS.OUTBOUND_DRIVE_FILL_RATE.READ_FULL,
        permission: PERMISSIONS_KEYS.outboundDriveFillRate
    },
    {
        key: 'outboundDrivePerformance',
        redirectTo: RoutesList.ANALYTICS.OUTBOUND_DRIVE_PERFORMANCE.READ_FULL,
        permission: PERMISSIONS_KEYS.outboundDrivePerformance
    },
    {
        key: 'tenderToCarrierPerformance',
        redirectTo:
            RoutesList.ANALYTICS.TENDER_TO_CARRIER_PERFORMANCE.READ_FULL,
        permission: PERMISSIONS_KEYS.tenderToCarrierPerformance
    },
    // ADMIN PANEL
    {
        key: 'choiceAdmins',
        redirectTo: RoutesList.ADMIN_PANEL.CHOICE_ADMIN.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.CHOICE_ADMIN.CREATE.FULL,
            RoutesList.ADMIN_PANEL.CHOICE_ADMIN.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: PERMISSIONS_KEYS.choiceAdmins
    },
    {
        key: 'profiles',
        redirectTo: RoutesList.ADMIN_PANEL.PROFILES.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.PROFILES.CREATE.FULL,
            RoutesList.ADMIN_PANEL.PROFILES.VIEW.FULL,
            RoutesList.ADMIN_PANEL.PROFILES.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: PERMISSIONS_KEYS.profiles
    },
    {
        key: 'admins',
        redirectTo: RoutesList.ADMIN_PANEL.ADMINS.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.ADMINS.CREATE.FULL,
            RoutesList.ADMIN_PANEL.ADMINS.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: PERMISSIONS_KEYS.admins
    },
    {
        key: 'users',
        redirectTo: RoutesList.ADMIN_PANEL.USERS.READ.FULL,
        nestedItems: [
            RoutesList.ADMIN_PANEL.USERS.CREATE.FULL,
            RoutesList.ADMIN_PANEL.USERS.EDIT.FULL
        ],
        getRedirectBlocker: ({ adminPanel }: RootState) =>
            adminPanel.redirectBlocker,
        permission: PERMISSIONS_KEYS.users
    }
];
