import React from 'react';

import { pageHeadersList } from '@constants/pageTitles';
import { usePageTitle } from '@helpers/usePageTitle';

interface IProps {
    error: Error;
    resetErrorBoundary: VoidFunction;
}

export const ErrorFallback = ({ error, resetErrorBoundary }: IProps) => {
    usePageTitle(pageHeadersList.SERVICE_PAGES.ERROR_FALLBACK);

    return (
        <div>
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};
