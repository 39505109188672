import React, { useEffect, useState } from 'react';

import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoutesList } from '@constants/routesList';
import { SetStateAction } from '@customTypes/general/general.types';
import {
    IRedirectList,
    getAllSideBarItems,
    RedirectList
} from '@molecules/siderMenu/SiderMenuItems';
import { useRedirectChecker } from '@molecules/siderMenu/useRedirectChecker';
import { useSiderMenuItems } from '@molecules/siderMenu/useSiderMenuItems';
import { setCurrentFlowId } from '@store/slices/user/user';
import { useAppDispatch, useAppSelector } from 'store';

import styles from './SiderMenu.module.less';

interface IProps {
    modalToggle: VoidFunction;
    setRedirectPath?: SetStateAction<string>;
    onMenuItemClick?: (isOpen: boolean) => void;
}

export const SiderMenu = ({
    modalToggle,
    setRedirectPath,
    onMenuItemClick
}: IProps) => {
    const userInfo = useAppSelector(({ user: { userInfo } }) => userInfo);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const navigation = useNavigate();
    const location = useLocation();
    const blockRedirectCondition = useRedirectChecker();
    const dispatch = useAppDispatch();
    const { sideBarItems } = useSiderMenuItems();

    useEffect(() => {
        dispatch(setCurrentFlowId(''));
        if (
            location.pathname ===
            `${RoutesList.ROOT}${RoutesList.TRACK_ORDERS.ROOT}`
        ) {
            setOpenKeys([]);
        } else {
            setOpenKeys([getDefaultSubMenuItem()]);
        }
    }, [location.pathname]);

    const getSelectedMenuItem = () => {
        const trigger = RedirectList.find((el) => {
            const locationIs = `/${el.redirectTo}` === location.pathname;
            const nestedLocationIs = el.nestedItems?.find((el) => {
                const isByIdPath = el.includes(':id');
                const withIdLocation = el.replace(':id', '');

                return isByIdPath
                    ? location.pathname.includes(withIdLocation)
                    : `/${el}` === location.pathname;
            });
            return locationIs || nestedLocationIs;
        });
        return trigger?.key ?? '';
    };

    const onSelect = (item: ItemType) => {
        onMenuItemClick?.(false);
        const element =
            item &&
            RedirectList.find((el: IRedirectList) => el.key === item.key);

        setRedirectPath && setRedirectPath(element?.redirectTo || '');

        if (blockRedirectCondition) {
            modalToggle();
            return;
        }

        if (element?.resetStateAction) {
            dispatch(element.resetStateAction());
        }

        if (element?.redirectTo) {
            navigation(element.redirectTo);
        }
    };

    const getDefaultSubMenuItem = () => {
        const trigger = getAllSideBarItems(userInfo).find(({ children }) =>
            children?.some(({ key }) => getSelectedMenuItem() === key)
        );

        return trigger?.key ?? '';
    };

    const onOpenChange = (openKeys: string[]) => {
        setOpenKeys([openKeys[openKeys.length - 1]]);
    };

    return (
        <Menu
            mode="inline"
            items={sideBarItems}
            className={styles.Menu}
            onSelect={onSelect}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={[getSelectedMenuItem()]}
            defaultSelectedKeys={[getSelectedMenuItem()]}
            defaultOpenKeys={[getDefaultSubMenuItem()]}
        />
    );
};
