import React from 'react';

import { Outlet } from 'react-router';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RoutesList } from '@constants/routesList';
import { CreatePassword } from '@organisms/auth/passwordRecovery/createPassword/CreatePassword';
import { EmailVerification } from '@organisms/auth/passwordRecovery/emailVerification/EmailVerification';
import { SignIn } from '@organisms/auth/signIn/SignIn';
import { AuthPage } from '@pages/auth/Auth.page';
import { HelpCenterPage } from '@pages/helpCenter/HelpCenter.page';
import { HomePage } from '@pages/home/Home.page';
import { Main } from '@pages/main/Main';
import { LocateClosestWarehousePage } from '@pages/manageInventory/locateClosestWarehouse/LocateClosestWarehouse.page';
import { NotAllowedPage } from '@pages/notAllowedPage/NotAllowed.page';
import { NotFoundPage } from '@pages/notFoundPage/NotFound.page';
import { OrdersListPage } from '@pages/trackOrders/ordersList/OrdersList.page';
import { UserSettingsPage } from '@pages/userSettings/UserSettings.page';
import { ProtectedRoute } from '@routes/ProtectedRoute';
import App from 'App';

import {
    CycleCountPerformancePage,
    DockToStockPage,
    GlobalCapabilitiesPage,
    OrderHistoryPage,
    OutboundDriveFillRatePage,
    DrivePerformancePage,
    TenderToCarrierPerformancePage,
    ClientBillingPage,
    ClientSpendReport,
    DutiesTaxesAnalysisReport,
    InvoiceReport,
    StorageBillingReport,
    InventoryOnHandPage,
    MaterialAnalysisPage,
    MissingPartsPage,
    OutstandingInboundsPage,
    OutstandingReturnsPage,
    TransactionHistoryPage,
    PartMasterPage,
    SubstitutePatsPage
} from './lazyDundasPages';
import {
    ChoiceAdminPage,
    AdminUsersPage,
    ChoiceAdminCreatePage,
    AdminsPage,
    AdminCreatePage,
    OrderByPartPage,
    AdminUserCreatePage,
    OutboundBulkPage,
    OrderDetailsPage,
    ProfilesCreatePage,
    ProfilesPage,
    MirDetailsPage,
    InboundBulkPage,
    InboundReturnPage,
    TransfersBulkPage,
    MaterialInReviewPage,
    InboundReplenishmentPage,
    TransferByWarehousePage,
    OrderByWarehousePage
} from './lazyOrderPages';

export const AppRoutes = () => {
    const {
        AUTH,
        ROOT,
        ORDERS,
        TRANSFERS,
        NOT_FOUND,
        TRACK_ORDERS,
        MATERIALS,
        MANAGE_INVENTORY,
        NOT_ALLOWED,
        ADMIN_PANEL,
        FINANCIALS,
        ANALYTICS,
        MANAGE_PARTS,
        HELP_CENTER,
        SETTINGS,
        HOME,
        RETURN_PARTS
    } = RoutesList;

    return (
        <Routes>
            <Route element={<App />}>
                <Route path={AUTH.ROOT} element={<AuthPage />}>
                    <Route index element={<SignIn />} />
                    <Route
                        path={AUTH.VERIFICATION}
                        element={<EmailVerification />}
                    />
                    <Route
                        path={AUTH.CREATE_PASS}
                        element={<CreatePassword />}
                    />
                </Route>
                <Route path={ROOT} element={<Main />}>
                    {/* Home */}
                    <Route path={HOME.ROOT} element={<Outlet />}>
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <HomePage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* TRACK ORDERS */}
                    <Route path={TRACK_ORDERS.ROOT} element={<Outlet />}>
                        <Route
                            index
                            element={
                                <ProtectedRoute>
                                    <OrdersListPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TRACK_ORDERS.ORDER_DETAILS.VIEW}
                            element={
                                <ProtectedRoute>
                                    <OrderDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* ORDERS */}
                    <Route path={ORDERS.ROOT} element={<Outlet />}>
                        <Route index element={<Navigate to={NOT_FOUND} />} />
                        <Route
                            path={ORDERS.BY_PART.CREATE}
                            element={
                                <ProtectedRoute>
                                    <OrderByPartPage isDefective={false} />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={ORDERS.BULK.CREATE}
                            element={
                                <ProtectedRoute>
                                    <OutboundBulkPage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* REPLENISHMENT */}
                    <Route path={MATERIALS.ROOT} element={<Outlet />}>
                        <Route
                            path={MATERIALS.REPLENISHMENT.CREATE}
                            element={
                                <ProtectedRoute>
                                    <InboundReplenishmentPage
                                        isDefective={false}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MATERIALS.BULK.CREATE}
                            element={
                                <ProtectedRoute>
                                    <InboundBulkPage isDefective={false} />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* Transfers */}
                    <Route path={TRANSFERS.ROOT} element={<Outlet />}>
                        <Route
                            path={TRANSFERS.BULK.CREATE}
                            element={
                                <ProtectedRoute>
                                    <TransfersBulkPage isDefective={false} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TRANSFERS.BULK_DEFECTIVE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <TransfersBulkPage isDefective={true} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TRANSFERS.BY_WAREHOUSE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <TransferByWarehousePage
                                        isDefective={false}
                                    />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TRANSFERS.BY_WAREHOUSE_DEFECTIVE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <TransferByWarehousePage
                                        isDefective={true}
                                    />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* RETURN PARTS */}
                    <Route path={RETURN_PARTS.ROOT} element={<Outlet />}>
                        <Route
                            path={RETURN_PARTS.DEFECTIVE_TO_CHOICE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <InboundReplenishmentPage
                                        isDefective={true}
                                    />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={
                                RETURN_PARTS.DEFECTIVE_TO_CHOICE_UPLOAD.CREATE
                            }
                            element={
                                <ProtectedRoute>
                                    <InboundBulkPage isDefective={true} />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={RETURN_PARTS.GOOD_TO_CHOICE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <InboundReturnPage />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={RETURN_PARTS.DEFECTIVE_FROM_CHOICE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <OrderByWarehousePage isDefective={true} />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={
                                RETURN_PARTS.DEFECTIVE_FROM_CHOICE_UPLOAD.CREATE
                            }
                            element={
                                <ProtectedRoute>
                                    <OutboundBulkPage isDefective={true} />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path={RETURN_PARTS.GOOD_FROM_CHOICE.CREATE}
                            element={
                                <ProtectedRoute>
                                    <OrderByWarehousePage isDefective={false} />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* MANAGE PARTS*/}
                    <Route path={MANAGE_PARTS.ROOT}>
                        <Route
                            path={MANAGE_PARTS.PART_MASTER.READ}
                            element={
                                <ProtectedRoute>
                                    <PartMasterPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MANAGE_PARTS.SUBSTITUTE_PARTS.READ}
                            element={
                                <ProtectedRoute>
                                    <SubstitutePatsPage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* MANAGE INVENTORY */}
                    <Route path={MANAGE_INVENTORY.ROOT} element={<Outlet />}>
                        <Route
                            path={MANAGE_INVENTORY.INVENTORY_ON_HAND.ROOT}
                            element={
                                <ProtectedRoute>
                                    <InventoryOnHandPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={
                                MANAGE_INVENTORY.LOCATE_CLOSEST_WAREHOUSE.ROOT
                            }
                            element={
                                <ProtectedRoute>
                                    <LocateClosestWarehousePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MANAGE_INVENTORY.MISSING_PARTS.ROOT}
                            element={
                                <ProtectedRoute>
                                    <MissingPartsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={
                                MANAGE_INVENTORY.INVENTORY_TRANSACTION_HISTORY
                                    .ROOT
                            }
                            element={
                                <ProtectedRoute>
                                    <TransactionHistoryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MANAGE_INVENTORY.OUTSTANDING_RETURNS.ROOT}
                            element={
                                <ProtectedRoute>
                                    <OutstandingReturnsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MANAGE_INVENTORY.OUTSTANDING_INBOUNDS.ROOT}
                            element={
                                <ProtectedRoute>
                                    <OutstandingInboundsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={
                                MANAGE_INVENTORY.MATERIALS_IN_REVIEW_ANALYSIS
                                    .ROOT
                            }
                            element={
                                <ProtectedRoute>
                                    <MaterialAnalysisPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={MANAGE_INVENTORY.MATERIALS_REVIEW.ROOT}
                            element={<Outlet />}
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute>
                                        <MaterialInReviewPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={
                                    MANAGE_INVENTORY.MATERIALS_REVIEW
                                        .MIR_DETAILS.VIEW
                                }
                                element={
                                    <ProtectedRoute>
                                        <MirDetailsPage />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                    </Route>

                    {/* ADMIN PANEL */}
                    <Route path={ADMIN_PANEL.ROOT} element={<Outlet />}>
                        <Route
                            path={ADMIN_PANEL.PROFILES.READ.ROOT}
                            element={<Outlet />}
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute>
                                        <ProfilesPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.PROFILES.CREATE.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <ProfilesCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.PROFILES.EDIT.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <ProfilesCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.PROFILES.VIEW.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <ProfilesCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ADMIN_PANEL.CHOICE_ADMIN.READ.ROOT}
                            element={<Outlet />}
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute>
                                        <ChoiceAdminPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.CHOICE_ADMIN.CREATE.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <ChoiceAdminCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.CHOICE_ADMIN.EDIT.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <ChoiceAdminCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ADMIN_PANEL.ADMINS.READ.ROOT}
                            element={<Outlet />}
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute>
                                        <AdminsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.ADMINS.CREATE.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <AdminCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.ADMINS.EDIT.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <AdminCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ADMIN_PANEL.USERS.READ.ROOT}
                            element={<Outlet />}
                        >
                            <Route
                                index
                                element={
                                    <ProtectedRoute>
                                        <AdminUsersPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.USERS.CREATE.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <AdminUserCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={ADMIN_PANEL.USERS.EDIT.ROOT}
                                element={
                                    <ProtectedRoute>
                                        <AdminUserCreatePage />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                    </Route>

                    {/* Financials*/}
                    <Route path={FINANCIALS.ROOT}>
                        <Route
                            path={FINANCIALS.INVOICES.VIEW}
                            element={
                                <ProtectedRoute>
                                    <InvoiceReport />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FINANCIALS.STORAGE_BILLING.VIEW}
                            element={
                                <ProtectedRoute>
                                    <StorageBillingReport />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FINANCIALS.CLIENT_SPEND.VIEW}
                            element={
                                <ProtectedRoute>
                                    <ClientSpendReport />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FINANCIALS.DUTIES_TAXES_ANALYSIS.VIEW}
                            element={
                                <ProtectedRoute>
                                    <DutiesTaxesAnalysisReport />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FINANCIALS.CLIENT_BILLING.VIEW}
                            element={
                                <ProtectedRoute>
                                    <ClientBillingPage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    {/* Analytics*/}
                    <Route path={ANALYTICS.ROOT}>
                        <Route
                            path={ANALYTICS.ORDER_HISTORY.READ}
                            element={
                                <ProtectedRoute>
                                    <OrderHistoryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.CYCLE_COUNT_PERFORMANCE.READ}
                            element={
                                <ProtectedRoute>
                                    <CycleCountPerformancePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.GLOBAL_CAPABILITIES.READ}
                            element={
                                <ProtectedRoute>
                                    <GlobalCapabilitiesPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.DOCK_TO_STOCK.READ}
                            element={
                                <ProtectedRoute>
                                    <DockToStockPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.OUTBOUND_DRIVE_FILL_RATE.READ}
                            element={
                                <ProtectedRoute>
                                    <OutboundDriveFillRatePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.OUTBOUND_DRIVE_PERFORMANCE.READ}
                            element={
                                <ProtectedRoute>
                                    <DrivePerformancePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ANALYTICS.TENDER_TO_CARRIER_PERFORMANCE.READ}
                            element={
                                <ProtectedRoute>
                                    <TenderToCarrierPerformancePage />
                                </ProtectedRoute>
                            }
                        />
                    </Route>

                    <Route path={SETTINGS} element={<UserSettingsPage />} />
                    <Route
                        path={ROOT}
                        element={<Navigate to={RoutesList.HOME.ROOT} />}
                    />
                    <Route path={HELP_CENTER} element={<HelpCenterPage />} />
                    <Route path={NOT_ALLOWED} element={<NotAllowedPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={NOT_FOUND} element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};
