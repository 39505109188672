import React, { useEffect, useState } from 'react';

import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import AuthApi from '@apis/auth/auth.api';
import { useUpdateLastLoginMutation } from '@apis/users/Users.api';
import { Loader } from '@atoms/loader/Loader';
import { getUpdateLastLogin, setUpdateLastLogin } from '@helpers/handleUser';
import {
    LOCAL_STORAGE_FIELDS,
    setLocalStorageItem
} from '@helpers/localStorage';
import { useUserSettings } from '@hooks/useUserSettings';
import { Header } from '@organisms/header/Header';
import { Sidebar } from '@organisms/sidebar/Sidebar';

import styles from './MainLayout.module.less';

export const MainLayout = () => {
    const [updateLastLogin] = useUpdateLastLoginMutation();
    const [isSiderOpenOnMobile, setSiderOpenOnMobile] =
        useState<boolean>(false);
    const { loading: userSettingsLoading, isDarkModeEnabled } =
        useUserSettings();

    useEffect(() => {
        if (!userSettingsLoading && isDarkModeEnabled !== undefined) {
            document.documentElement.setAttribute(
                'data-theme',
                isDarkModeEnabled ? 'dark' : 'light'
            );
            setLocalStorageItem(
                LOCAL_STORAGE_FIELDS.DARK_MODE,
                String(isDarkModeEnabled)
            );
        }
    }, [userSettingsLoading]);

    useEffect(() => {
        if (!getUpdateLastLogin()) {
            updateLastLogin();
            setUpdateLastLogin();
        }
    }, []);

    const actions = {
        signOut: AuthApi.signOut
    };

    return (
        <>
            {userSettingsLoading && (
                <div className={styles.LoaderContainer}>
                    <Loader />
                </div>
            )}
            {!userSettingsLoading && (
                <>
                    <Sidebar
                        isSiderOpen={isSiderOpenOnMobile}
                        toggleSiderOnMobile={setSiderOpenOnMobile}
                        signOut={actions.signOut}
                    />
                    <Layout>
                        <div className={styles.Content}>
                            <Header
                                actions={actions}
                                toggleSiderOnMobile={setSiderOpenOnMobile}
                                isSiderOpenOnMobile={isSiderOpenOnMobile}
                            />
                            <div className={styles.MainContent}>
                                <div className={styles.MainWrapper}>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </Layout>
                </>
            )}
        </>
    );
};
