export const RoutesList = {
    ROOT: '/',
    NOT_FOUND: '/not-found',
    AUTH: {
        ROOT: 'auth',
        SIGN_UP: 'signup',
        VERIFICATION: 'verification',
        CREATE_PASS: 'create-password'
    },
    NOT_ALLOWED: 'not-allowed',
    SETTINGS: 'user-settings',
    HELP_CENTER: 'help-center',
    TRACK_ORDERS: {
        ROOT: 'track-orders',
        ORDER_DETAILS: {
            VIEW: ':id',
            VIEW_FULL: 'track-orders/:id'
        }
    },
    HOME: {
        ROOT: 'home'
    },
    ORDERS: {
        ROOT: 'customer-delivery',
        BY_PART: {
            CREATE: 'good-parts',
            CREATE_FULL: 'customer-delivery/good-parts'
        },
        BULK: {
            CREATE: 'good-parts-upload',
            CREATE_FULL: 'customer-delivery/good-parts-upload'
        }
    },
    MATERIALS: {
        ROOT: 'replenishments',
        REPLENISHMENT: {
            CREATE: 'good-parts',
            CREATE_FULL: 'replenishments/good-parts'
        },
        BULK: {
            CREATE: 'good-parts-upload',
            CREATE_FULL: 'replenishments/good-parts-upload'
        }
    },
    TRANSFERS: {
        ROOT: 'warehouse-transfers',
        BY_WAREHOUSE: {
            CREATE: 'good-parts',
            CREATE_FULL: 'warehouse-transfers/good-parts'
        },
        BULK: {
            CREATE: 'good-parts-upload',
            CREATE_FULL: 'warehouse-transfers/good-parts-upload'
        },
        BY_WAREHOUSE_DEFECTIVE: {
            CREATE: 'defective-parts',
            CREATE_FULL: 'warehouse-transfers/defective-parts'
        },
        BULK_DEFECTIVE: {
            CREATE: 'defective-parts-upload',
            CREATE_FULL: 'warehouse-transfers/defective-parts-upload'
        }
    },

    RETURN_PARTS: {
        ROOT: 'return-parts',
        DEFECTIVE_TO_CHOICE: {
            CREATE: 'defective-to-choice',
            CREATE_FULL: 'return-parts/defective-to-choice'
        },
        DEFECTIVE_TO_CHOICE_UPLOAD: {
            CREATE: 'defective-to-choice-upload',
            CREATE_FULL: 'return-parts/defective-to-choice-upload'
        },
        GOOD_TO_CHOICE: {
            CREATE: 'good-to-choice',
            CREATE_FULL: 'return-parts/good-to-choice'
        },
        DEFECTIVE_FROM_CHOICE: {
            CREATE: 'defective-from-choice',
            CREATE_FULL: 'return-parts/defective-from-choice'
        },
        DEFECTIVE_FROM_CHOICE_UPLOAD: {
            CREATE: 'defective-from-choice-upload',
            CREATE_FULL: 'return-parts/defective-from-choice-upload'
        },
        GOOD_FROM_CHOICE: {
            CREATE: 'good-from-choice',
            CREATE_FULL: 'return-parts/good-from-choice'
        }
    },

    ANALYTICS: {
        ROOT: 'analytics',
        ORDER_HISTORY: {
            READ: 'order-history',
            READ_FULL: 'analytics/order-history'
        },
        CYCLE_COUNT_PERFORMANCE: {
            READ: 'cycle-count-performance',
            READ_FULL: 'analytics/cycle-count-performance'
        },
        GLOBAL_CAPABILITIES: {
            READ: 'global-capabilities',
            READ_FULL: 'analytics/global-capabilities'
        },
        DOCK_TO_STOCK: {
            READ: 'dock-to-stock',
            READ_FULL: 'analytics/dock-to-stock'
        },
        OUTBOUND_DRIVE_FILL_RATE: {
            READ: 'outbound-drive-fill-rate',
            READ_FULL: 'analytics/outbound-drive-fill-rate'
        },
        OUTBOUND_DRIVE_PERFORMANCE: {
            READ: 'outbound-drive-performance',
            READ_FULL: 'analytics/outbound-drive-performance'
        },
        TENDER_TO_CARRIER_PERFORMANCE: {
            READ: 'tender-to-carrier-performance',
            READ_FULL: 'analytics/tender-to-carrier-performance'
        }
    },
    MANAGE_PARTS: {
        ROOT: 'manage-parts',
        PART_MASTER: {
            READ: 'part-master',
            READ_FULL: 'manage-parts/part-master'
        },
        SUBSTITUTE_PARTS: {
            READ: 'substitute-parts',
            READ_FULL: 'manage-parts/substitute-parts'
        }
    },
    MANAGE_INVENTORY: {
        ROOT: 'manage-inventory',
        MATERIALS_REVIEW: {
            ROOT: 'material-in-review',
            FULL: 'manage-inventory/material-in-review',
            MIR_DETAILS: {
                VIEW: ':id',
                VIEW_FULL: 'manage-inventory/material-in-review/:id'
            }
        },
        MATERIALS_IN_REVIEW_ANALYSIS: {
            ROOT: 'material-in-review-analysis',
            FULL: 'manage-inventory/material-in-review-analysis'
        },
        INVENTORY_ON_HAND: {
            ROOT: 'inventory-on-hand',
            FULL: 'manage-inventory/inventory-on-hand'
        },
        LOCATE_CLOSEST_WAREHOUSE: {
            ROOT: 'locate-closest-warehouse',
            FULL: 'manage-inventory/locate-closest-warehouse'
        },
        MISSING_PARTS: {
            ROOT: 'missing-parts',
            FULL: 'manage-inventory/missing-parts'
        },
        INVENTORY_TRANSACTION_HISTORY: {
            ROOT: 'inventory-transaction-history',
            FULL: 'manage-inventory/inventory-transaction-history'
        },
        OUTSTANDING_RETURNS: {
            ROOT: 'outstanding-returns',
            FULL: 'manage-inventory/outstanding-returns'
        },
        OUTSTANDING_INBOUNDS: {
            ROOT: 'outstanding-inbounds',
            FULL: 'manage-inventory/outstanding-inbounds'
        }
    },
    FINANCIALS: {
        ROOT: 'financials',
        INVOICES: {
            VIEW: 'invoices',
            VIEW_FULL: 'financials/invoices'
        },
        STORAGE_BILLING: {
            VIEW: 'storage-billing',
            VIEW_FULL: 'financials/storage-billing'
        },
        CLIENT_SPEND: {
            VIEW: 'client-spend',
            VIEW_FULL: 'financials/client-spend'
        },
        CLIENT_BILLING: {
            VIEW: 'client-billing',
            VIEW_FULL: 'financials/client-billing'
        },
        DUTIES_TAXES_ANALYSIS: {
            VIEW: 'duties-taxes-analysis',
            VIEW_FULL: 'financials/duties-taxes-analysis'
        }
    },
    ADMIN_PANEL: {
        ROOT: 'administration',
        ADMINS: {
            READ: {
                ROOT: 'admins',
                FULL: 'administration/admins'
            },
            CREATE: {
                ROOT: 'new-admin',
                FULL: 'administration/admins/new-admin'
            },
            EDIT: {
                ROOT: 'edit-admin',
                FULL: 'administration/admins/edit-admin'
            }
        },
        USERS: {
            READ: {
                ROOT: 'users',
                FULL: 'administration/users'
            },
            CREATE: {
                ROOT: 'new-user',
                FULL: 'administration/users/new-user'
            },
            EDIT: {
                ROOT: 'edit-user',
                FULL: 'administration/users/edit-user'
            }
        },
        PROFILES: {
            READ: {
                ROOT: 'profiles',
                FULL: 'administration/profiles'
            },
            CREATE: {
                ROOT: 'new-profile',
                FULL: 'administration/profiles/new-profile'
            },
            EDIT: {
                ROOT: 'edit-profile',
                FULL: 'administration/profiles/edit-profile'
            },
            VIEW: {
                ROOT: 'view-profile',
                FULL: 'administration/profiles/view-profile'
            }
        },
        CHOICE_ADMIN: {
            READ: {
                ROOT: 'choice-administrators',
                FULL: 'administration/choice-administrators'
            },
            CREATE: {
                ROOT: 'new-administrator',
                FULL: 'administration/choice-administrators/new-administrator'
            },
            EDIT: {
                ROOT: 'edit-administrator',
                FULL: 'administration/choice-administrators/edit-administrator'
            }
        }
    }
};
