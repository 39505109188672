import React, { Dispatch, SetStateAction } from 'react';

import { Avatar } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@atoms/dropdown/Dropdown';
import { RoutesList } from '@constants/routesList';
import { convertNameToInitials } from '@helpers/convertNameToInitials';
import { useRedirectChecker } from '@molecules/siderMenu/useRedirectChecker';
import { UserTokenInfo } from '@store/slices/user/user';
import {
    SvgActionDotsHorizontal,
    SvgQuestion,
    SvgSettings,
    SvgSignOut
} from 'components/icons';

import styles from './UserInfoWidget.module.less';

interface IProps {
    signOut: VoidFunction;
    userInfo?: UserTokenInfo | null;
    className: string;
    dropdownClassName: string;
    modalToggle?: VoidFunction;
    setRedirectPath?: Dispatch<SetStateAction<string>>;
    onMenuItemClick?: (isOpen: boolean) => void;
}

export const UserInfoWidget = ({
    signOut,
    userInfo,
    className,
    dropdownClassName,
    modalToggle,
    setRedirectPath,
    onMenuItemClick
}: IProps) => {
    const navigate = useNavigate();
    const blockRedirectCondition = useRedirectChecker();

    const onItemClick = (path: string) => {
        onMenuItemClick?.(false);
        setRedirectPath?.(path);
        if (path === RoutesList.ROOT) {
            blockRedirectCondition ? modalToggle?.() : signOut();
            return;
        }
        blockRedirectCondition ? modalToggle?.() : navigate(path);
    };

    return (
        <div
            className={cn(styles.Row, styles.UserInfo, styles.Gap, className)}
            id="user-profile-dropdown"
        >
            <Dropdown
                className={cn(styles.UserInfo__Dropdown, dropdownClassName)}
                items={[
                    {
                        label: 'User Settings',
                        icon: <SvgSettings />,
                        onClick: () => onItemClick(RoutesList.SETTINGS)
                    },
                    {
                        label: 'Help Center',
                        icon: <SvgQuestion />,
                        onClick: () => onItemClick(RoutesList.HELP_CENTER)
                    },
                    {
                        label: 'Sign out',
                        icon: <SvgSignOut />,
                        onClick: () => onItemClick(RoutesList.ROOT)
                    }
                ]}
                trigger={
                    <div className={styles.Container}>
                        <div
                            className={cn(styles.Row, styles.Gap)}
                            id="user-profile-trigger"
                        >
                            <Avatar>
                                {convertNameToInitials({
                                    firstName: userInfo?.firstName,
                                    lastName: userInfo?.lastName
                                })}
                            </Avatar>
                            <div className={styles.Column}>
                                <div
                                    className={styles.UserInfo__Name}
                                    id="UserName"
                                >
                                    {userInfo?.firstName} {userInfo?.lastName}
                                </div>
                            </div>
                        </div>
                        <div className={styles.ActionContainer}>
                            <SvgActionDotsHorizontal />
                        </div>
                    </div>
                }
            />
        </div>
    );
};
