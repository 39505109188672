import React from 'react';

import { ILabelProps } from '@atoms/label/Label';
import { Refresh } from '@atoms/refresh/Refresh';
import { Status } from '@customTypes/general/general.types';
import { areUniqueArraysEqual } from '@helpers/array';
import {
    getFormattedStatuses,
    getStatusVariant,
    MIR_STATUSES,
    PROGRESS_STATUSES
} from '@helpers/orderStatus';
import {
    IComparedFilterValues,
    ISelectedFilters
} from '@organisms/allFiltersWrapper/AllFiltersWrapper';
import { OrderStatusLabel } from '@organisms/mirAndTracking/orderStatusLabel/OrderStatusLabel';
import styles from '@organisms/mirAndTracking/orderStatusLabelsBar/OrderStatusLabelsBar.module.less';

interface IProps {
    statuses: Status[];
    onRefreshClick: VoidFunction;
    onStatusSelect?: (status: PROGRESS_STATUSES | MIR_STATUSES) => void;
    lastUpdated?: string;
    defaultStatusVariant?: ILabelProps['variant'];
    disabled?: boolean;
    selectedStatus?: PROGRESS_STATUSES | MIR_STATUSES;
    isMobile?: boolean;
    setNewFilters?: (newFilter: ISelectedFilters) => void;
    setComparedResults?: (newComparedValue: IComparedFilterValues) => void;
    selectedStatusItem?: PROGRESS_STATUSES | MIR_STATUSES;
    setSelectedStatusItem?: (
        statusItem: PROGRESS_STATUSES | MIR_STATUSES
    ) => void;
}

export const OrderStatusLabelsBar = ({
    statuses,
    onRefreshClick,
    lastUpdated,
    defaultStatusVariant,
    disabled,
    selectedStatus,
    onStatusSelect,
    isMobile = false,
    selectedStatusItem,
    setSelectedStatusItem,
    setNewFilters,
    setComparedResults
}: IProps) => {
    const onCurrentStatusSelect = (
        status: PROGRESS_STATUSES | MIR_STATUSES
    ) => {
        const prevStatuses = selectedStatusItem
            ? selectedStatusItem.split('|')
            : [];
        const { newStatus, selectedStatuses } = getFormattedStatuses(
            prevStatuses,
            status
        );

        setSelectedStatusItem?.(
            (newStatus as PROGRESS_STATUSES | MIR_STATUSES) || 'null'
        );

        const isStatusesEqual = areUniqueArraysEqual(
            selectedStatus ? selectedStatus.split('|') : [],
            selectedStatuses
        );
        setComparedResults?.({ isStatusChanged: isStatusesEqual });
        setNewFilters?.({ selectedStatus: newStatus || 'null' });
    };

    const selectedItem = isMobile ? selectedStatusItem : selectedStatus;

    return (
        <div
            className={isMobile ? styles.ContainerMobile : styles.Container}
            id={!isMobile ? 'OrderStatusLabelBar' : 'OrderStatusLabelBarMobile'}
        >
            <div
                className={styles.Labels}
                id={
                    !isMobile
                        ? 'OrderStatusLabelBar'
                        : 'OrderStatusLabelBarMobile'
                }
            >
                {statuses?.map((status, index) => {
                    return (
                        <OrderStatusLabel
                            variant={
                                defaultStatusVariant
                                    ? defaultStatusVariant
                                    : getStatusVariant(status.label)
                            }
                            status={status.label}
                            count={status.value}
                            key={`${status}_${index}`}
                            disabled={disabled}
                            selected={selectedItem
                                ?.split('|')
                                .some((item) => item === status.label)}
                            isMobile={isMobile}
                            onStatusClick={
                                isMobile
                                    ? onCurrentStatusSelect
                                    : onStatusSelect
                            }
                        />
                    );
                })}
            </div>

            {!isMobile && (
                <Refresh
                    onRefreshClick={onRefreshClick}
                    lastUpdated={lastUpdated}
                />
            )}
        </div>
    );
};
