import { IB2C } from '@customTypes/general/appConfig.types';
import { GetConfig } from '@customTypes/general/b2C.types';

export class B2CController {
    protected static instance: B2CController;

    protected config: GetConfig = {
        clientId: '',
        scopes: { login: '' },
        authPath: ''
    };

    public static getInstance(): B2CController {
        if (!B2CController.instance) {
            B2CController.instance = new B2CController();
        }

        return B2CController.instance;
    }

    public setB2CData(config: IB2C) {
        this.config = {
            scopes: { login: config.scope },
            clientId: config.clientId,
            authPath: config.authPath
        };
    }

    public getConfig(): GetConfig {
        return this.config;
    }
}
