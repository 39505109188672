import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';

import { NullableTokenClaims } from '@apis/auth/authApi.types';
import { usersApi } from '@apis/users/Users.api';
import { UserInfo } from '@apis/users/UsersApi.types';
import { USERS } from '@customTypes/users/permissions.types';
import {
    getLocalStorageItem,
    LOCAL_STORAGE_FIELDS
} from '@helpers/localStorage';
import { IAction } from 'store/index';

export type UserTokenInfo = Partial<
    UserInfo & {
        isChoiceAdmin: boolean;
        isAdmin: boolean;
        isUser: boolean;
    }
>;

const getUserInfoFromToken = (acToken: string | null) => {
    const claims: NullableTokenClaims = acToken ? jwt(acToken) : null;

    if (claims) {
        return claims.oid || '';
    }
};

const initialState: {
    currentFlowId: string;
    userInfo: Partial<
        UserInfo & {
            isChoiceAdmin: boolean;
            isAdmin: boolean;
            isUser: boolean;
        }
    >;
    userId?: string;
} = {
    currentFlowId: '',
    userInfo: {},
    userId: getUserInfoFromToken(
        getLocalStorageItem(LOCAL_STORAGE_FIELDS.ACCESS_TOKEN)
    )
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentFlowId: (state, action: IAction<string>) => {
            state.currentFlowId = action.payload;
        },
        setUserId: (state, action: IAction<string>) => {
            state.userId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            usersApi.endpoints?.getUserInfo.matchFulfilled,
            (state, action) => {
                const isEmpty = Object.keys(state.userInfo).length === 0;

                if (!isEmpty) return;

                state.userInfo = {
                    isChoiceAdmin:
                        action.payload.data.userType === USERS.CHOICE_ADMIN,
                    isAdmin: action.payload.data.userType === USERS.ADMIN,
                    isUser: action.payload.data.userType === USERS.USER,
                    ...action.payload.data
                };
            }
        );
    }
});

export const { setUserId, setCurrentFlowId } = userSlice.actions;
